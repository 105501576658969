







import { Component, Vue } from "vue-property-decorator";

@Component({
  props: {
    event: { type: Object },
  },
})
export default class ViewEvent extends Vue {
  show = false;

  open() {
    this.show = true;
  }

  handleClose() {
    // 
  }

  handleOpen() {
    // 
  }
}
