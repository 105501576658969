



































































































import { Component, Mixins } from "vue-mixin-decorator";
import TranslateMixin from "@/mixins/TranslateMixin";
import EventStore from "@/store/event/index";
import { IEventListSort, IEvent } from "@/store/event/interface";
import { formatRangeDates } from "@/utils/index";
import ViewEvent from "@/views/events/modals/ViewEvent.vue"

const defaultFilters = JSON.stringify({
  page: 1,
  limit: 10,
  ownerType: [],
  ownerId: [],
  createdAt: [],
  search: "",
});

const defaultSorts = JSON.stringify({
  id: "DESC",
});

@Component({
  name: "EventsPage",
  components: { ViewEvent },
})
export default class CashboxesPage extends Mixins<TranslateMixin>(
  TranslateMixin
) {
  selectedEvent = {} as IEvent;
  loading = true;
  showFilters = false;
  ownerIdChange: any = null

  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);

  $refs!: {
    ViewEvent: ViewEvent;
  };

  mounted() {
    this.init();
  }

  async init() {
    await this.getEventList();
  }

  async getEventList() {
    this.loading = true;    
    await EventStore.getEventsList({
      filter: this.filters,
      sort: this.sorts,
    });
    this.loading = false;
    this.closeFilters();
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getEventList();
  }

  async changePage(page: number) {
    this.filters.page = page;
    this.getEventList();
  }

  closeFilters() {
    this.showFilters = false;
  }

  clearFilters() {
    this.filters = JSON.parse(defaultFilters);
    this.ownerIdChange = null
    this.getEventList();
  }
  
  async changeSort(e: {
    column: string;
    prop: keyof IEventListSort;
    order: string;
  }): Promise<void> {
    this.sorts = {};

    const sortMap: { [key: string]: keyof IEventListSort } = {
      id: "id",
      ownerType: "ownerType",
      ownerId: "ownerId",
    };

    if (!sortMap[e.prop]) return;

    if(e.order === null){
      this.sorts[sortMap["id"]] = "DESC"
    }
    else{
      const direction =  e.order === "descending" ? "DESC" : "ASC"
      this.sorts[sortMap[e.prop]] = direction;
    }

    this.getEventList();
  }

  async changeSearch() {
    // updateQuery(this, this.filters.search);
    this.getEventList();
  }

  changeOwnerId(){
    this.ownerIdChange === "" ? this.filters.ownerId = []
    : this.filters.ownerId[0] = this.ownerIdChange
  }
  
  handleActions(event: any) {
    this.selectedEvent = event.row;

    if (event.name === "RemoveEvent") {
      this.$confirm(
        `Вы действительно хотите удалить событие ${this.selectedEvent.id} ?`,
        "Осторожно",
        {
          confirmButtonText: "Удалить",
          cancelButtonText: "Отмена",
          center: true,
          confirmButtonClass: "el-button--danger",
          type: "error",
        }
      ).then(async () => {
        await EventStore.deleteEvent(this.selectedEvent.id);
        this.getEventList();
      });
    }

    if (event.name === "ViewEvent") {
      this.$refs.ViewEvent.open();
    }
  }

  changeDate(dates: string[]) { 
    this.filters.createdAt = formatRangeDates(dates);
  }

  handleClose() {
    console.log("close");
  }

  translateOwnerId(ownerId: number, ownerType: number) {
    if(ownerType === 4) 
      return this.translate("accounts", ownerId)
    else if(ownerType === 3)
      return this.translate("workers", ownerId)
    else if(ownerType === 0)
      return this.translate("organization", this.translate("cashboxOrganizationId", ownerId))
  }

  get eventsList() {
    return EventStore.eventList;
  }

}
